import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/atrium/atrium_img1.png'
import img2 from '../../assets/images/atrium/atrium_img2.png'
import img3 from '../../assets/images/atrium/atrium_img3.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionFullImage,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import { HeadlineM, TitleM, ParagraphL, ParagraphM, ParagraphS } from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'Grafično oblikovanje',
    'Idejna zasnova',
    'Grafično oblikovanje',
    'Idejna zasnova',
    'Grafično oblikovanje',
    'Idejna zasnova',
    'Grafično oblikovanje',
    'Idejna zasnova',
    'Grafično oblikovanje',
    'Idejna zasnova',
    'Grafično oblikovanje',
    'Idejna zasnova',
    'Grafično oblikovanje',
    'Idejna zasnova',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | Atrium Interieri"
        description="Skupaj z naročnikom Atrium Interieri smo zasnovali zloženko, ki je ljudje ne bodo brez
              pomisleka zavrgli. Posebno jo delata magnetni platnici, med katerimi se skrivajo opis
              podjetja in vizitka."
        image={img1}
      />
      <Header
        logoBackground={colors.blue}
        logoBorder={colors.blue}
        linkText={colors.blue}
        menuLines={colors.blue}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="Atrium Interieri" />
          </div>
          <Square background={colors.blue} className="title">
            <ParagraphM color={colors.white}>Atrium Interieri</ParagraphM>
            <HeadlineM color={colors.yellow}>Zloženka</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.blue}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Izdelati brošuro s predstavitvijo podjetja, ki bo služila namesto vizitke na sejmih in
              bo ostala v spominu.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Skupaj z naročnikom Atrium Interieri smo zasnovali zloženko, ki je ljudje ne bodo brez
              pomisleka zavrgli. Posebno jo delata magnetni platnici, med katerimi se skrivajo opis
              podjetja in vizitka.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-top-padding reduce-bottom-padding">
            <Content>
              {/* Example Title */}
              <h2>Žepna zloženka</h2>

              {/* Example Paragraph */}
              <p>
                Naročnik si je želel na sejmih potencialnim strankam vročiti nekaj več kot le
                klasično vizitko, hkrati pa ni želel postati še en v kupu katalogov. Zato smo se
                odločili za izdelavo zgibanke žepne velikosti.
              </p>
              <p>
                Poleg same velikosti, zgibanko naredijo žepu prijazno še magnetne platnice, ki
                onemogočajo neželjeno odpiranje zgibanke. Magneti hkrati naredijo izdelek bolj
                atraktiven in vsakdo, ki ga prime v roke, ga z veseljem prebere (in se nato še nekaj
                časa z njim igra 🙃).
              </p>
            </Content>
          </SectionRichText>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img2} alt="Vitasis" />
          </SectionFullImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-bottom-padding">
            <Content>
              {/* Example Paragraph */}
              <p>
                Naročnik je želel v zloženki predstaviti ključna področja na katerih delujejo in
                njihovo glavno konkurenčno prednost - enostaven in hiter proces od ideje do montaže
                pohištva.
              </p>
              <p>
                Da zloženka ne bi vsebovala preveč teksta, smo različna področja predstavili s
                fotografijami referenčnih projektov, proces pa s pomočjo časovnice in ikon.
              </p>
            </Content>
          </SectionRichText>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img3} alt="Vitasis" />
          </SectionFullImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content></Content>
          </SectionRichText>
        </div>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          <Link to="/project/ip-posocje">
            <ParagraphL>← Prejšnji projekt </ParagraphL>
          </Link>
          {/* <Link to="/project/tolmin-2030"> */}
          <ParagraphL className="disabled">Naslednji projekt →</ParagraphL>
          {/* </Link> */}
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
